import React, { Component } from 'react';

import {FormattedMessage, injectIntl} from 'react-intl';
import { connect } from "react-redux";
import _ from 'lodash';
import Modal from 'react-modal';
import {isIosStatusBar} from 'service/LayoutService';

import 'components/layout/productWizard/productWizardMasterjet.scss';
import {MdMoreVert as Ellipsis} from "react-icons/md";
import {getMasterjetProducts} from "storeState/data/selectors";
import {getMasterjetConfigurationParams} from "storeState/ui/selectors";

import MODAL_TYPE from "./masterJetModals/MasterjetModalTypes.constants";
import ModalProduct from "./masterJetModals/ModalProduct";
import ModalProductChoose from "./masterJetModals/ModalProductChoose";
import ModalProductApplication from "./masterJetModals/ModalProductApplication";
import ModalNozzleCount from "./masterJetModals/ModalNozzleCount";
import ModalNozzleAmount from "./masterJetModals/ModalNozzleAmount";
import ModalNozzleDiameter from "./masterJetModals/ModalNozzleDiameter";
import ModalPerformanceClass from "./masterJetModals/ModalPerformanceClass";
import ModalThread from "./masterJetModals/ModalThread";
import ModalPressure from "./masterJetModals/ModalPressure";
import ModalVariableSpeed from "./masterJetModals/ModalVariableSpeed";

const iosStatusBar = isIosStatusBar();

class ProductWizardMasterjet extends Component {

    static propTypes = {}

    static defaultProps = {}

    constructor(props) {
        super(props);

        this.state = {
          activeModal: null
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {}

    openModal = (activeModal) => {
        this.setState({
            activeModal : activeModal,
        })
    }

    closeModal = () => {
        this.setState({
            activeModal : null,
        })
    }

    getCurrentProductName = () => {
      let item = null;
      if(this.props.calculationParams?.productId) {
        item = _.find(this.props.masterjetProducts, product => product.id === this.props.calculationParams.productId);
      }
      if(item) {
        return (
          <div>
            <div className={"product-wizard-visible-desktop"}>
              <span>{item.name}</span><br/>
            </div>
            <div className={"product-wizard-visible-mobile"}>
              {item.name}
            </div>
          </div>
        );
      } else {
        return <FormattedMessage id="product_wizard_masterjet_choose" defaultMessage="Wählen Sie direkt Ihren Masterjet-Typ"/>
      }
    }

    getFilteredMasterjetProducts = (filters=[]) => {

      const filteredProducts = _.filter(this.props.masterjetProducts, product => {

        let result = true;

        if(filters.indexOf("productType") > -1) {
          if(this.props.calculationParams?.productType === "manual") {
            result = result && (product.dataunits.manual_use_allowed.value === 1.0 || product.dataunits.manual_use_allowed.value === "1.0")
          }
        }
        if(filters.indexOf("maxNozzleCount") > -1) {
          const maxNozzle = parseInt(_.max(_.keys(product.dataunits.numnoz_to_alpha_avg.value)));
          result = result && maxNozzle === this.props.calculationParams?.maxNozzleCount
        }
        if(filters.indexOf("performanceClass") > -1)  {
          let pc = parseInt(product.dataunits.PC.value)
          if(isNaN(pc)) pc = 0;
          result = result && pc === this.props.calculationParams?.performanceClass
        }
        if(filters.indexOf("threadType") > -1) {
          if (this.props.calculationParams?.threadType) {
            const calcThread = this.props.calculationParams?.threadType;
            const threadType = product.dataunits.threadType.value;
            if (calcThread === 'A') {
              result = result && threadType === 'A';
            } else if (calcThread === 'M') {
              result = result && threadType === 'M';
            } else if (calcThread === 'G') {
              result = result && threadType === 'G';
            } else if (calcThread === 'M_G') {
              result = result && (threadType === 'M' || threadType === 'G');
            }
          }
        }
        if(filters.indexOf("maxPressure") > -1) {
          const maxPressure = parseInt(product.dataunits.p_max.value);
          result = result && maxPressure <= this.props.calculationParams?.maxPressure
        }
        if(filters.indexOf("variableSpeed") > -1) {
          const variableSpeed = product.dataunits.variableSpeed.value === "1.0";
          const calcSpeed = this.props.calculationParams?.variableSpeed === "1.0";
          result = result && variableSpeed === calcSpeed;
        }

        return result;
      })

      return filteredProducts;
    }

    render() {

        return (
            <div>
                <div className="product-wizard-masterjet-item" onClick={()=>this.openModal(MODAL_TYPE.MODAL_TYPE_PRODUCT)}>
                    <div className="product-wizard-masterjet-item-label">{this.getCurrentProductName()}</div>
                    <Ellipsis className="product-wizard-masterjet-label-ellipsis" />
                </div>
                <Modal isOpen={ this.state.activeModal != null } className={`ci-modal ci-modal-nopadding product-wizard-masterjet-modal-scroller ${iosStatusBar?'product-wizard-masterjet-modal-ios-status-bar':''}`} overlayClassName="ci-modal-overlay" onRequestClose={this.closeModal}>
                    <Choose>

                      <When condition={ this.state.activeModal === MODAL_TYPE.MODAL_TYPE_PRODUCT }>
                        <ModalProduct openModal={this.openModal} closeModal={this.closeModal} />
                      </When>

                      <When condition={ this.state.activeModal === MODAL_TYPE.MODAL_TYPE_PRODUCT_CHOOSE }>
                        <ModalProductChoose
                          filteredProducts={this.getFilteredMasterjetProducts()}
                          openModal={this.openModal}
                          closeModal={this.closeModal}
                        />
                      </When>

                      <When condition={ this.state.activeModal === MODAL_TYPE.MODAL_TYPE_PRODUCT_APPLICATION }>
                        <ModalProductApplication
                          openModal={this.openModal}
                          closeModal={this.closeModal}
                        />
                      </When>

                      <When condition={ this.state.activeModal === MODAL_TYPE.MODAL_TYPE_NOZZLE_COUNT }>
                        <ModalNozzleCount
                          filteredProducts={this.getFilteredMasterjetProducts([
                            'productType',
                          ])}
                          openModal={this.openModal}
                          closeModal={this.closeModal}
                        />
                      </When>

                      <When condition={ this.state.activeModal === MODAL_TYPE.MODAL_TYPE_NOZZLE_AMOUNT }>
                        <ModalNozzleAmount
                          filteredProducts={this.getFilteredMasterjetProducts([
                            'productType',
                            'maxNozzleCount'
                          ])}
                          openModal={this.openModal}
                          closeModal={this.closeModal}
                        />
                      </When>

                      <When condition={ this.state.activeModal === MODAL_TYPE.MODAL_TYPE_NOZZLE_DIAMETER }>
                        <ModalNozzleDiameter openModal={this.openModal} closeModal={this.closeModal} />
                      </When>

                      <When condition={ this.state.activeModal === MODAL_TYPE.MODAL_TYPE_PERFORMANCE_CLASS }>
                        <ModalPerformanceClass
                          filteredProducts={this.getFilteredMasterjetProducts([
                            'productType',
                            'maxNozzleCount'
                          ])}
                          openModal={this.openModal}
                          closeModal={this.closeModal}
                        />
                      </When>

                      <When condition={ this.state.activeModal === MODAL_TYPE.MODAL_TYPE_THREAD }>
                        <ModalThread
                          filteredProducts={this.getFilteredMasterjetProducts([
                            'productType',
                            'maxNozzleCount',
                            'performanceClass'
                          ])}
                          openModal={this.openModal}
                          closeModal={this.closeModal}
                        />
                      </When>

                      <When condition={ this.state.activeModal === MODAL_TYPE.MODAL_TYPE_PRESSURE }>
                        <ModalPressure
                          filteredProducts={this.getFilteredMasterjetProducts([
                            'productType',
                            'maxNozzleCount',
                            'performanceClass',
                            'threadType'
                          ])}
                          openModal={this.openModal}
                          closeModal={this.closeModal}
                        />
                      </When>

                      <When condition={ this.state.activeModal === MODAL_TYPE.MODAL_TYPE_VARIABLE_SPEED }>
                        <ModalVariableSpeed
                          filteredProducts={this.getFilteredMasterjetProducts([
                            'productType',
                            'maxNozzleCount',
                            'performanceClass',
                            'threadType',
                            'maxPressure'
                          ])}
                          openModal={this.openModal}
                          closeModal={this.closeModal}
                        />
                      </When>

                      <When condition={ this.state.activeModal === MODAL_TYPE.MODAL_TYPE_PRODUCT_CHOOSE_AFTER_WIZARD }>
                        <ModalProductChoose
                          filteredProducts={this.getFilteredMasterjetProducts([
                            'productType',
                            'maxNozzleCount',
                            'performanceClass',
                            'threadType',
                            'maxPressure',
                            'variableSpeed'
                          ])}
                          openModal={this.openModal}
                          closeModal={this.closeModal}
                        />
                      </When>

                    </Choose>
                </Modal>
            </div>
        )
    }
}

function mapStateToProps(state, props) {

  const calculationParams = getMasterjetConfigurationParams(state);
  const masterjetProducts = getMasterjetProducts(state);

    return {
      masterjetProducts: masterjetProducts,
      calculationParams: calculationParams || {}
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductWizardMasterjet))
